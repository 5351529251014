// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    background: linear-gradient(147deg, rgba(11, 41, 0, 0.586) 4%, rgba(255, 255, 255, 0.5) 96%);    
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-attachment: fixed;

}

.header{
    text-align: center;
}

.header h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 60px;
    text-shadow: 3px 2px rgb(45, 8, 55);
    color: #ffffff;
}

p {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    margin-top: 0;
}

.canvas {
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    margin-top: 3%;
}

#file-input {
    z-index: 1;
    position: relative;
    left:52%;
    transform: translateX(-52%);
    font-size: 15px;
}
#three-canvas{
    position: relative;
    height: 100% !important;
    width: 100% !important;
    z-index: 10;

}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,4FAA4F;IAC5F,YAAY;IACZ,SAAS;IACT,4BAA4B;IAC5B,4BAA4B;;AAEhC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,mCAAmC;IACnC,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,QAAQ;IACR,OAAO;IACP,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;;AAEf","sourcesContent":["body{\r\n    background: linear-gradient(147deg, rgba(11, 41, 0, 0.586) 4%, rgba(255, 255, 255, 0.5) 96%);    \r\n    height: 100%;\r\n    margin: 0;\r\n    background-repeat: no-repeat;\r\n    background-attachment: fixed;\r\n\r\n}\r\n\r\n.header{\r\n    text-align: center;\r\n}\r\n\r\n.header h1{\r\n    font-family: 'Open Sans', sans-serif;\r\n    font-size: 60px;\r\n    text-shadow: 3px 2px rgb(45, 8, 55);\r\n    color: #ffffff;\r\n}\r\n\r\np {\r\n    font-family: 'Open Sans', sans-serif;\r\n    font-size: 20px;\r\n    color: #ffffff;\r\n    text-align: center;\r\n    margin-top: 0;\r\n}\r\n\r\n.canvas {\r\n    position:absolute;\r\n    left:50%;\r\n    top:50%;\r\n    transform: translate(-50%, -50%);\r\n    margin-top: 3%;\r\n}\r\n\r\n#file-input {\r\n    z-index: 1;\r\n    position: relative;\r\n    left:52%;\r\n    transform: translateX(-52%);\r\n    font-size: 15px;\r\n}\r\n#three-canvas{\r\n    position: relative;\r\n    height: 100% !important;\r\n    width: 100% !important;\r\n    z-index: 10;\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
